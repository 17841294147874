var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container text-center"},[_c('b-form-tags',{staticClass:"mb-2 mt-2",attrs:{"tag-variant":"success","disabled":_vm.disabled,"no-outer-focus":"","placeholder":"Enter a new tag value and click Add","state":_vm.state},on:{"input":function($event){return _vm.resetInputValue()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputId = ref.inputId;
var placeholder = ref.placeholder;
var disabled = ref.disabled;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":inputId,"placeholder":placeholder,"disabled":disabled,"formatter":_vm.formatter},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return addTag(_vm.newTag)}},model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":disabled,"variant":"primary"},on:{"click":function($event){return addTag(_vm.newTag)}}},[_vm._v("Add")])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.state}},[_vm._v(" Duplicate tag value cannot be added again! ")]),(tags.length > 0)?_c('ul',{staticClass:"mb-0"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"mt-2",attrs:{"title":("Tag: " + tag)}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(tag))]),_c('b-button',{attrs:{"disabled":disabled,"size":"sm","variant":"outline-danger"},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" remove tag ")])],1)])}),0):_c('b-form-text',[_vm._v(" There are no tags specified. Add a new tag above. ")])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }